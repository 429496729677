import Notify from 'quasar/src/plugins/notify/Notify.js';;

export default class CanUserAccessClass {
    canUserAccess(permission, notify = true){
        
        let array = JSON.parse(localStorage.getItem("vuex")).menu;
        let result = array.filter(element => {
            return element.modulo === permission;
        });
        if(result.length === 0){
            if(notify){
                Notify.setDefaults({
                    position: 'top',
                    timeout: 5000,
                    textColor: 'white',
                    color: 'red-9'
                })

                Notify.create('Você não tem permissão para executar está ação.')
            }
            return false;
        }
        return true;
    }

    isUserAgent(){
        let userData = JSON.parse(localStorage.getItem("vuex")).userData;
        let roleId = userData.id_papel;
       
        if(roleId !== 4){
            return false;
        }
        return true;
    }

    userRedirectRoute(route){
      let phoneRoute = JSON.parse(localStorage.getItem("vuex")).phoneRoute;
      
      return phoneRoute === route; 
  }
}